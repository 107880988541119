
<template>

    <div class="">

      <b-modal id="aipanel" v-model="aipanelshow" hide-backdrop hide-footer hide-header size="md" no-close-on-esc no-close-on-backdrop>

      <a class = "close-modal dialog-close-button mr-2 float-right">
          <i class="fal fa-times fa-lg " @click="hideaipanelshow()" ></i>
        </a>

      <div class="clearfix"></div>

      <div ref="systemreference" style="position: absolute;top:-10000px; left: -10000px">
You will be provided with a project status. Your task is to create a summary of the project status based on the project status provided.<br>
The provided project status is structured in the following way:<br>
The project status consists of one or more project objectives and for each objective there are published status updates. A status update has a status publish date, a status author, a status indicator, and status content. 
If a project objective does not have any status updates, then do not include the project objective in the summary.
If a project objective has more than one status update, then put more emphasis on the latest status update.<br>
Parameters for the summary:<br>
Tone: {{ system_tone }}<br>
Summary length: maximum {{ system_text_length }} characters<br>
Language: {{ system_language }}<br>
Summary style: {{ system_style=='Bullet list' ? 'Bullet list with one bullet for every objective' : system_style }}<br> 
</div>

  <div ref="userinputreference" style="position: absolute;top:-10000px; left: -10000px">
  <template v-for="(objective,key) in objectives">
  <template v-if="objective.ischecked">
  Objective title: {{ objective.name }}<br :key="'br1'+key">
  Objective description: {{ domText(objective.description) }}<br :key="'br2'+key">
  <template v-for="(indicator,key2) in objective.indicators">
  <template v-if="indicator.ischecked">
  <template v-if="indicator.metadata">
  Status publish date: {{ indicator.indicatordate | dateformat }}<br :key="'br3'+key+'_'+key2">
  Status author: {{ indicator.userdetail.name }}<br :key="'br4'+key+'_'+key2">
  Status indicator: {{ indicator.color }} = {{ indicator.statusoption.label }}<br :key="'br5'+key+'_'+key2">
  Status content: {{ indicator.metadata ? indicator.metadata.usertext : "" }} <br :key="'br6'+key+'_'+key2">
  <br :key="'br7'+key+'_'+key2">

  <template v-if="objective.measuretype=='risk'">
  The 3 biggest risks: <br :key="'br8'+key+'_'+key2">
  <template v-for="(item,key3) in topRisks(indicator)">
  {{key3+1}}. {{item.label}}<br :key="'br9'+key+'_'+key3">{{item.description}}<br :key="'br9b'+key+'_'+key3">
  </template>
  </template>

  <template v-if="objective.measuretype=='masterplan'">
  <template v-if="delayedMilestones(indicator)">
  <template v-if="delayedMilestones(indicator).length > 0">
  Delayed milestones: <br :key="'br10'+key2">
  <template v-for="(item,key3) in delayedMilestones(indicator)">
  {{ item.delay.label }}. Scheduled date = {{ item.delay.eventdate }}. A milestone on the {{ item.track.name }} track<br :key="'br11'+key+'_'+key3">
  </template>
  </template>
  <template v-else>
  No delayed milestones<br :key="'br12'+key2">
  </template>
  </template>
  </template>

  <br v-if="key2 <= objective.indicators.length" :key="'br17'+key+'_'+key2">
  </template>

  </template>

  </template> 
  <br :key="'br18'+key">

  </template>
  </template>
  </div>


      <div class="card mt-2 mb-2" :key="aipanelkey">

        <textarea v-model="gptresponse" id="gptresponsepanel" style="visibility: hidden; position: absolute;"></textarea> 

        <b-tabs content-class="mt-3" pills card end active-nav-item-class="active-tab"
    >
          <b-tab title="Response" active>
            <div class="card-body" v-html="gptresponse" :style="'min-height: ' + parseInt(system_text_length)/2  + 'px;'"></div>
          </b-tab>
          <b-tab title="System">
            <div style="width: 100%;" v-html="textDom(systemtext)"></div>
          </b-tab>
          <b-tab title="User">
            <textarea style="width: 100%;min-height: 300px;" v-model="builtusertext"></textarea>
          </b-tab>
        </b-tabs>

             
        
      </div>

      <div class="pb-2 pt-2" v-if="!working">

        <b-dropdown id="select-gptspecs-1" :text="system_tone" class="m-md-1" size="sm">
          <b-dropdown-item @click="system_tone='Business formal'">Business formal</b-dropdown-item>
          <b-dropdown-item @click="system_tone='Professional'">Professional</b-dropdown-item>
          <b-dropdown-item @click="system_tone='Friendly'">Friendly</b-dropdown-item>
          <b-dropdown-item @click="system_tone='Casual'">Casual</b-dropdown-item>
        </b-dropdown>


        <b-dropdown id="select-gptspecs-2" :text="system_language" class="m-md-1" size="sm">

          <b-dropdown-item @click="system_language='English'">English</b-dropdown-item>
          <b-dropdown-item @click="system_language='Danish'">Danish</b-dropdown-item>

        </b-dropdown>

        <b-dropdown id="select-gptspecs-3" :text="system_style" class="m-md-1" size="sm">
          <b-dropdown-item @click="system_style='1 paragraph'">1 paragraph</b-dropdown-item>
          <b-dropdown-item @click="system_style='2 paragraph'">2 paragraph</b-dropdown-item>
         
          <b-dropdown-item @click="system_style='Bullet list'">Bullet list</b-dropdown-item>
        </b-dropdown>


      </div>

       <div class="pb-2 pt-2" v-if="working">

        <b-dropdown id="select-gptspecs-1" :text="system_tone" class="m-md-1" size="sm" :disabled="true">
        </b-dropdown>


        <b-dropdown id="select-gptspecs-2" :text="system_language" class="m-md-1" size="sm" :disabled="true">
        </b-dropdown>

        <b-dropdown id="select-gptspecs-3" :text="system_style" class="m-md-1" size="sm" :disabled="true">
        </b-dropdown>

        <b-dropdown id="select-gptspecs-4" :text="system_text_length_text()" class="m-md-1" size="sm" :disabled="true">
        </b-dropdown>

      </div>

      <div class="d-flex">
        <div class="p-2">
          <button v-if="!working && hasgenerated" class="btn btn-secondary" @click="usethistext()">Use&nbsp;this</button>
          <button v-else class="btn btn-secondary" disabled>Use&nbsp;this</button></div>
        <div class="p-2">
          <button v-if="!working" class="btn btn-primary" @click="rungenerate()">Generate</button>
          <button v-else class="btn btn-primary" disabled>Generate</button></div>
      </div>

    
      

     </b-modal> 


    </div>


</template>

<style type="text/css">

  .active-tab{
    background: #fff !important;
    color: #000 !important;
  }
  
  .h5{
    font-size: 15px;
    font-weight: bold;
  }

</style>


<script type="text/javascript">
  
</script>

<script>
  
  import axios from 'axios'
  //import settrelloboard from '@/views/tools/trello/settrelloboard'  

  export default{
    name:'aipanel',

    props:{
      aipanelshow:{
        type:Boolean,
        default:()=>false
      },
      timeperiodstart:{
        type:String,
        default:()=>""
      },
      timeperiodend:{
        type:String,
        default:()=>""
      },
    },

    data(){
      return{
        //aipanelshow:true,
        hasgenerated:false,
        aipanelkey:0,
        gptresponse:"",
        indicators:[],
        board:{},
        objectives:{},
        builtusertext:"",
        system_tone:"Professional",
        system_text_length:"400",
        system_language:"English",
        system_style:"Bullet list",
        systemtext:"",
        builtusertext:"",
        working:false,
        startdate:"",
        enddate:""
      }
    },

    watch:{

      aipanelshow: function( e ){

        let self = this

        //if( e == true ){

          this.working = false
          this.gptresponse = ""
          this.hasgenerated = false

          /*setTimeout( function(){
            self.builduser( true ) 
          }, 200)*/

           
        //}

      }
      
    },
    mounted(){


      this.loadindicators()

    },
    methods:{

      rungenerate(){

        this.builduser(true)
      },

      system_text_length_text(){

        let toreturn = 'Default'

        if( this.system_text_length == '600' ){
          toreturn = 'Expanded'
        }
        if( this.system_text_length == '300' ){
          toreturn = 'Compact'
        }

        return toreturn
      },

      usethistext(){

        var copyText = document.getElementById("gptresponsepanel");

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

         // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);

        // Alert the copied text
        alert("Summary is copied to clipboard.");


        this.$emit('hideaipanel')
      },

      hideaipanelshow(){

        this.$emit('hideaipanel')

      },

      resubmit(){

        //let usertext = ""

        //this.builtusertext = usertext

        //if( makerequest ){

        //  setTimeout( function(){
        this.makegptrequest()
        //  }, 200)

        //}

      },

      updateselectedindicators(){

        let self = this

        setTimeout( function(){
          self.builduser( false ) 
        },200)
      },

      updateselected( objective ){

        let self = this

        setTimeout( function(){
          self.builduser( false ) 
        },200)
        
        setTimeout( function(){
          for( let i in objective.indicators ){
            objective.indicators[i].ischecked = objective.ischecked// ? false : true
          }
        },100)

        




      },

      topRisks(indicator){

        let metadata = indicator.metadata ? indicator.metadata : {}

        if( !metadata.risks ){
          return null
        }

        let risks = []
        for( let i in metadata.risks ){
          risks.push( metadata.risks[i]  )
        }

        return risks
      },

      delayedMilestones( indicator ){

        let metadata = indicator.metadata ? indicator.metadata : {}

        if( !metadata.tracks ){
          return null
        }

        let milestonescount = 0



        let delayed = []

        for( let i in metadata.tracks ){

          let track = metadata.tracks[i]

          if( track.milestones ){

            milestonescount += track.milestones.length

            for( let o in track.milestones ){

              if( track.milestones[o].status.toLowerCase() == "red" ){
                delayed.push( { "track": { "id": track._id, "name": track.label } , delay: track.milestones[o] }  )
              }
              
            }
            
          }

          
        }

        if( milestonescount == 0 ){
          return null
        }

        return delayed

      },

      builduser( makerequest ){

        this.aipanelkey += 1
        let self = this

        if( this.$refs.systemreference && this.$refs.userinputreference ){

          setTimeout( function(){

            self.systemtext = self.$refs.systemreference.innerText
            let usertext = self.$refs.userinputreference.innerText

            self.builtusertext = usertext

            if( makerequest ){          
                self.makegptrequest()  
            }

          }, 100)


        }



        
      },

      formatresponse( str ){

        let newstr = str.replaceAll("\n","<br>")
        return newstr
      },


      async makegptrequest(){

        this.hasgenerated = false
        this.working = true
        this.gptresponse = "Working..."

        let self = this


        let config = {
        "model": "gpt-4-1106-preview",
        "messages": [
        {
        "role": "system",
        "content": this.systemtext
        },
        {
        "role": "user",
        "content": this.builtusertext
        }
        ],
        "temperature": 1,
        "max_tokens": 256,
        "top_p": 1,
        "frequency_penalty": 0,
        "presence_penalty": 0
      }

      
          const CHAT_GPT_KEY = process.env.VUE_APP_OPENAI_KEY;
          const data = config
          // Make the API call to ChatGPT
          const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${CHAT_GPT_KEY}`
            },
            body: JSON.stringify(data)
          });
          const { choices } = await response.json();
          // Insert the suggestion into selected text

          if( choices ){

            
            this.gptresponse = ""
          

            let responsetext = this.formatresponse(choices[0].message.content)
            
            var speedoptions = [5, 15,25,35, 45, 55]
            var speed = 25
            var i = 0;

            function typeWriter() {
              if (i < responsetext.length) {
                self.gptresponse +=  responsetext.charAt(i);
                i++;
                var nextspeed = Math.floor(Math.random() * 3)

                if( speedoptions[nextspeed] ) {
                  speed = speedoptions[nextspeed]
                }

                setTimeout(typeWriter, speed);

              }else{
                self.working = false
                self.hasgenerated = true
              }
            }

            typeWriter()

          }else{

            alert('ChatGPT is not available at this moment.')
          }

          

          

      },

      textDom( htmlstr ){

        return htmlstr.replaceAll("\n", "<br>")
      },


      domText( htmlstr ){

        const parser = new DOMParser();
        let existinghtml = parser.parseFromString(htmlstr, 'text/html');
        return existinghtml.body.innerText

      },

      loadindicators(){

        let self = this
        this.working = true

        let url = "https://dev2api.flowe.io"
        //let url = "http://localhost:8081"

        axios.get( url + "/Tools/Loadbuilder?cid="+this.cockpitid+"&startdate="+this.timeperiodstart+"&enddate="+this.timeperiodend ).then( function( response ){
          self.working = false
          self.board = response.data.board
          self.objectives = response.data.objectives

          /*setTimeout( function(){
            self.builduser(true)
          }, 200)*/

        })
      },


    },
    computed:{

      

      cockpitid(){
            return this.$route.params.cid
        },



    },
    components:{ },

  }
  //  
</script>
<template>

  
  <div :class="{'inactive-cockpit':!cockpit.isactive}">

    
    <TopBar hasdescription v-if ="tabsobject['typeofpage']=='myobjectives'" :classes="'cockpit'" :columnnumber = "columnnumber" @showdescription ="cockpitdescriptionmodalshownO = true" :hovertitle="cockpit.hovertitle" :hidedropdown="true" :stakeholdersenabled="stakeholdersenabled"  >
     
      <template  v-slot:title >
        Objectives
      </template>

      <template v-slot:description v-if="cockpit.description">
        <i class="infoicon fal fa-info-circle"></i>
      </template>  

      <template v-slot:icon>
        <!-- <i class="fal fa-dot-circle  align-self-center "></i> -->
      </template>
      
    </TopBar>

    <TopBar hasdescription v-if ="tabsobject['typeofpage']=='cockpit'" :classes="'cockpit'" :activetopbar="activetopbar" @showdescription ="oncockpitdescription()" :hovertitle="cockpit.hovertitle" :backarrow="true" :pathback="{path: '/' + orgkey + '/boards'}" @opentabs = "opentabs" :tabsobject ="tabsobject.tapes" :stakeholdersenabled="stakeholdersenabled">
      <template v-slot:icon>
        <!-- <i class="fal fa-border-all align-self-center "></i> -->
      </template>

      <template v-slot:title>
        {{cockpit.name}}
      </template>  
      
      <template v-slot:description>
        <i class="infoicon description-icon far fa-info-circle fa-sm"></i>
      </template>

      <template v-slot:inactive v-if="cockpit.isactive==false">
        <span class="ml-1 badge badge-light">Inactive</span>  
      </template>

      <template v-slot:righticons>
         
          <base-icon-label class ="menu-icon" iconSize ="sm" @click ="loadmemberlist('cockpit' ,cockpit._id, null)" icon ="far fa-users"  labelafter ="Members"/>
          <base-icon-label v-if="cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER'"  class ="menu-icon"  iconSize ="sm" @click.prevent="cockpitsettings(cockpit._id)" icon ="far fa-cog"  labelafter =""/>

       
      </template>
      <template v-slot:righticonsdropdown>
         
            <a class="d-flex align-items-center statusopts">
                <div class="d-flex flex-row w-100" >
                  <base-icon-label class ="dropdown-item"  @click ="loadmemberlist('cockpit' ,cockpit._id, null)" icon ="far fa-users"  labelafter ="Members"/>
       
               </div>
            </a>
              <a class="d-flex align-items-center statusopts">
                <div class="d-flex flex-row w-100" >
                  
                  <base-icon-label v-if="cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER'"  class ="dropdown-item"  iconSize ="sm" @click.prevent="cockpitsettings(cockpit._id)" icon ="far fa-cog"  labelafter ="Settings"/>
               </div>
            </a>
        </template>
    </TopBar>

  
    <main class="main-wrapper">

      <span v-if="orgerror == 'orgdisabled'">This section is unavailable because the organization is currently inactive. You can re-activate it from via the organization settings.</span>

      <b-modal v-model="cockpitdescriptionmodalshownO" id="cockpitdescriptionmodal" size="lg" hide-footer>
       <template v-slot:modal-header>
        <h3 class="modal-title">Objectives</h3>
        <a class = "close-modal dialog-close-button">
          <i class="fal fa-times fa-lg " @click="cockpitdescriptionmodalshownO=false;" ></i>
        </a>
      </template> 
      <div>
        <p>This page shows all the objectives you have access to regardless of which boards they may be linked to. From here you can:</p>
        <ul>
          <li>Read status updates by clicking on an objective</li>

          <li>Add status updates to objectives where you are either owner or contributor - use the objective menu '…'</li>

          <li>Create new objectives by clicking on the 'Create new objective' card</li>

          <li>Give other people access to your objectives via the objectives settings found in the menu '…' of each objective</li>
        </ul>
      </div>
    </b-modal>
    
    <b-modal id='objectivemodal' modal-class="modal-cockpit fullscreen-modal" v-model="objectivemodalshown" size="lg" no-fade hide-footer no-close-on-backdrop no-close-on-esc @shown="onobjectivemodalshown" @hidden="onobjectivemodalhidden" v-if="modalobjective" >
      <template v-slot:modal-header>
        <h3 class="modal-title">{{modalobjective.name}}</h3>
        <a class = "close-modal dialog-close-button">
          <i class="fal fa-times fa-lg " @click="objectivemodalshown=false;"></i>
        </a>
      </template>

      <template v-if="modalobjective">

        <p :class="{'hide':objectivemodalfailed==false}">We’re sorry, but we are unable to load the contents of this section. Try refreshing the page and <a target="_blank" class="text-primary" href="https://help.flowe.io">let us know</a> if the error persists.</p>

        <div :class="{'hide':objectivemodalfailed==true}">
        <objectivesCardfull :activeCockpit="cockpit.isactive" ref="objectivedata1" :objective="modalobjective" :indicatorid="modalindicatorid" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="modalobjectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" :usemenu="true" :useswipe="false" :fulldesc="true" :scope="'linked-index'" :feedback="'readwrite'" :perpage="10"></objectivesCardfull>
        </div>
      </template>
    </b-modal>


    <membersModal :isactive = "cockpit.isactive" :myrole="myactiverole" :members="cockpitmembers" :checkforinvalidlink = "false" :membersmodaltype="membersmodaltype" :membersmodaleid="membersmodaleid" :shown="membersmodalshown" @onmodalhidden="membersModalHidden" :loggedinuser="loggedinuser" :token="token" @oncockpitupdated="boardupdated" @onobjectiveupdated="boardupdated"></membersModal>
   
    
    <addStatustoaggregator :deviseDesktop ="deviseDesktop" :shown="addstatustoaggregator.show" :cockitid="cockpitid" :userid="loggedinuser._id" :objectivedata="addstatustoaggregator.objective" :objectiveid="addstatustoaggregator.objective._id" @onmodalhidden="addstatustoaggregator.show=false" @onaddnewindicatorsuccess="addnewindicatorsuccess" :token="token" ></addStatustoaggregator>
  

    <addStatustoindicator :board="cockpit" :deviseDesktop="deviseDesktop" :shown="addstatustoindicator.show" :cockitid="cockpitid" :objectivedata="addstatustoindicator.objective" @onmodalhidden="addstatustoindicator.show=false" @onaddnewindicatorsuccess="addnewindicatorsuccess" :token="token" ></addStatustoindicator>


    <requestingaccess 
    :cockpitid="cockpit._id" 
    :objectiveid="activeobjective._id" 
    :userid="loggedinuser._id"  
    :shown="requestaccessmodalshown" 
    @onmodalhidden = "requestaccessmodalshown=false" 
    @onrequestsubmitted="requestsubmitted">  
  </requestingaccess>

  
  

  <b-modal v-model="showmakeactive.show" centered size="sm-p" no-close-on-backdrop no-close-on-esc>
    <template v-slot:modal-header>
      <h3 class="modal-title board_headeractivation"><span v-if="showmakeactive.type == 'cockpit' && cockpit.isactive">Make board inactive</span><span v-else-if = "
      showmakeactive.type == 'cockpit' && !cockpit.isactive"> Board is inactive</span> <span v-if="showmakeactive.type == 'objective'">Make objective inactive</span></h3>
      <a class = "close-modal dialog-close-button">
        <i class="fal fa-times fa-lg " @click="showmakeactive.show=false"></i>
      </a>
    </template>

    <div v-if="showmakeactive.type == 'cockpit'">
      <div v-if ="cockpit.isactive">
        <p>You are about to make this board inactive. This means that users will no longer have access to this board.</p>
        <p>It is possible to make the board active again - no data will be deleted.</p>
        <p>Are you sure you want to make this board inactive?</p>
      </div>
      <div v-else>
        <p>The settings are not available because this board is inactive. Would you like to re-activate it?</p>
      </div>


    </div>

    <div v-if="showmakeactive.type == 'objective'">
      <p>You are about to make this objective inactive. This means that users will no longer have access to this objective and contributors will not be able to add status updates.</p>
      <p>No data will be deleted, and it is possible to make the objective active again.</p>
      <p>Are you sure you want to make this objective inactive?</p>
    </div>

    <template v-slot:modal-footer="{ ok,cancel }">
      <b-button @click="cancel()">Cancel</b-button>      
      <b-button v-if=" cockpit.isactive || showmakeactive.type == 'objective' " class="btn-primary" @click="domakeactive( 'no', showmakeactive.type, showmakeactive.iid )">Make inactive</b-button>  
      <b-button v-else class="btn-primary" @click="domakeactive( 'yes', showmakeactive.type, showmakeactive.iid )">  Make board active </b-button>             
    </template>
  </b-modal>

  <cockpitdescription 
  :shown="cockpitdescriptionmodalshown"
  @onmodalhidden  = "onmodaldescriptionhidden" 
  @oncockpitupdated="cockpitupdates++"
  ></cockpitdescription>


   <div class="cockpitview2" id="portfoliopages" style = "height: 100%"  v-if="tabsobject.currenttap =='portfolio'">
     
    <portfolio v-if="routename=='portfolioovervieworgs'"></portfolio>

    <portfoliodetail v-if="routename=='portfoliooverviewdetail'"></portfoliodetail>
   </div>

 <div class="cockpitview2" id="boardreportspages" style = "height: 100%"  v-if="tabsobject.currenttap =='boardreports'">
    <div class="measures" id="boardreports">

     <newsletterpages
     v-if="routename == 'boardreports'"
     :token="token" 
     :cockpitdata="cockpit"
     :cockpitid="cockpit._id" 
     :shown="newslettermodalshown" 
     :newsletterid="opennewsletterid" 
     :loggedinuser="loggedinuser"
     />

     <reportsedit
     ref="reportseditconf"
     v-if="routename == 'boardreportsedit'"
     :token="token" 
     :cockpitdata="cockpit"
     :cockpitid="cockpit._id" 
     :shown="newslettermodalshown" 
     :newsletterid="opennewsletterid" 
     :loggedinuser="loggedinuser"
     />

     <reportsview
     ref="reportsviewconf"
     v-if="routename == 'boardreportsview'"
     :token="token" 
     :cockpitdata="cockpit"
     :cockpitid="cockpit._id" 
     :shown="newslettermodalshown" 
     :newsletterid="opennewsletterid" 
     :loggedinuser="loggedinuser"
     />
     
   </div>
 </div>

 

 <objectivesettingsmodal 
 :checkforinvalidlink="true"
 :objectiveid="activeobjective._id" 
 :loggedinuser="loggedinuser" 
 :token="token" 
 :shown="objectivesettingsmodalshown" 
 @onmakeactive="makeactive"
 @onmodalhidden="onobjectivesettingsmodalhidden"
 @onobjectiveupdated="objectiveupdated"></objectivesettingsmodal>

 <cockpitStatistics :shown="cockpitstatisticsmodalshown" :cockpitdata="cockpit" :cockpitid="cockpit._id" @onopenmeasureinmodal="openmeasureinmodal" @onmodalhidden="cockpitstatisticsmodalshown = false" :token="token" v-if="tabsobject.currenttap=='boardstatistics'"></cockpitStatistics>


 <stakeholders :shown="cockpitstatisticsmodalshown" :cockpitdata="cockpit" :cockpitid="cockpit._id" @onopenmeasureinmodal="openmeasureinmodal" @onmodalhidden="cockpitstatisticsmodalshown = false" :token="token" v-if="tabsobject.currenttap=='boardstakeholders'"></stakeholders>

 <riskmanagement :shown="cockpitstatisticsmodalshown" :cockpitdata="cockpit" :cockpitid="cockpit._id" @onopenmeasureinmodal="openmeasureinmodal" @onmodalhidden="cockpitstatisticsmodalshown = false" :token="token" v-if="tabsobject.currenttap=='boardriskmanagement'"></riskmanagement>

  <masterplan :shown="cockpitstatisticsmodalshown" :cockpitdata="cockpit" :cockpitid="cockpit._id" @onopenmeasureinmodal="openmeasureinmodal" @onmodalhidden="cockpitstatisticsmodalshown = false" :token="token" v-if="tabsobject.currenttap=='boardmasterplan'"></masterplan>

 
  <linkages v-if="tabsobject.currenttap=='boarditems'"></linkages>

 <cockpitsettingsmodal 
 :checkforinvalidlink="false"
 :cockpitid="cockpitid" 
 :loggedinuser="loggedinuser" 
 :token="token" 
 :shown="cockpitsettingsmodalshown" 
 :myrole ="cockpit.myrole"
 :isactive="cockpit.isactive"
 @onforceupdate="cockpitforceupdates"
 @onmodalhidden="oncockpitsettingsmodalhidden" 
 @oncockpitupdated="cockpitupdates++"
 @onmakeactive="makeactive"
 ></cockpitsettingsmodal>


 <div class="cockpitview" id="cockpitview" style = "height: 100%" v-if="( tabsobject.currenttap =='board' || tabsobject.currenttap =='boardoverview' || tabsobject['typeofpage'] == 'myobjectives') && status == 'idle'" :key="cockpitindexupdated"> 

  <template v-for="(section, index ) in groupeditem">   

    <div class="mt-3 measures cardsofmeasures clearfix" :key="section._id">

      <h4 style="width: 100%;"><span style="background-color: #ffffff; padding-right: 20px;" v-text="section.label"></span></h4>

      <template v-if="section.objectivesdata && (cockpit.isactive==true || ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER'))">

       <template v-if="columnnumber == 1">

        <objectivesCard  v-for="objective in section.objectivesdata" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard>

        <objectivesadd :parentpage="tabsobject['typeofpage']" :class="{hide:newobjectiveworking}" v-if="cockpit.isactive && index == 0 && tabsobject['typeofpage']=='myobjectives'" :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>

      </template>    


      <template v-if="columnnumber == 2">
        <div id="objectives-left">

          <objectivesCard  v-for="objective in leftsection(section.objectivesdata)" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard>

          <objectivesadd :parentpage="tabsobject['typeofpage']" v-if="((rightsection(section.objectivesdata).length >= leftsection(section.objectivesdata).length) && cockpit.isactive && index == 0 && tabsobject['typeofpage']=='myobjectives' ) && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER')" :class="{hide:newobjectiveworking} " :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>

        </div>


        <div id="objectives-right">

          <objectivesCard  v-for="objective in rightsection(section.objectivesdata)" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard> 

          <objectivesadd :parentpage="tabsobject['typeofpage']" v-if="(rightsection(section.objectivesdata).length < leftsection(section.objectivesdata).length) && cockpit.isactive && index == 0 && tabsobject['typeofpage']=='myobjectives'" :class="{hide:newobjectiveworking}" :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>


        </div>
      </template>



    </template>

    <template v-if="!section.objectivesdata">
       <template v-if="columnnumber == 1">

         <div v-if="!(((rightsection(section.objectivesdata).length >= leftsection(section.objectivesdata).length) && cockpit.isactive && index == 0 && tabsobject['typeofpage']=='myobjectives' ) && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER') )"> - there are no objectives in this section -</div>
         <objectivesadd  :parentpage="tabsobject['typeofpage']" v-else-if="index == 0 && tabsobject['typeofpage']=='myobjectives'" :class="{hide:newobjectiveworking} " :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>


      </template>
      <template v-else>
        <div id="objectives-left">
        <div v-if="!(((rightsection(section.objectivesdata).length >= leftsection(section.objectivesdata).length) && cockpit.isactive && index == 0 && tabsobject['typeofpage']=='myobjectives' ) && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER') )"> - there are no objectives in this section -</div>
         <objectivesadd  :parentpage="tabsobject['typeofpage']" v-else-if="index == 0 && tabsobject['typeofpage']=='myobjectives'" :class="{hide:newobjectiveworking}" :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>
      </div>
      <div id="objectives-right">&nbsp; </div>       
      </template>
    
    </template>    

    <p class="clearfix"></p>

  </div>

</template>

<div class="measures mt-3" id="cardsofmeasures" v-if="tabsobject.typeofpage=='cockpit' && ( cockpit.measuresnotinsections && cockpit.measuresnotinsections.length >= 1 || ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER')  )"> 

 <h4 style="width: 100%;" v-if="cockpit.sections && cockpit.sections.length"> <span style="background-color: #ffffff; padding-right: 20px;"> Not grouped</span><p class="border-top" style="margin-top: -9px;">&nbsp;</p></h4>

 <template v-if="columnnumber == 1">

  <objectivesCard  v-for="objective in cockpit.measuresnotinsections" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" @oncommentsupdated="updatecomments" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard>

  <objectivesCardworking v-if="cockpit.isactive && newobjectiveworking"></objectivesCardworking>

  <objectivesadd :class="{hide:newobjectiveworking}" v-if="cockpit.isactive && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER')  " :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>

</template>


<template v-if="columnnumber == 2">
  <div id="objectives-left">

    <objectivesCard  v-for="objective in leftcockpitmeasures" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" @oncommentsupdated="updatecomments" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard>

    <objectivesCardworking v-if="rightcockpitmeasures.length >= leftcockpitmeasures.length && cockpit.isactive && newobjectiveworking"></objectivesCardworking>

    <objectivesadd :class="{hide:newobjectiveworking}" v-if="(rightcockpitmeasures.length >= leftcockpitmeasures.length && cockpit.isactive) && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER')" :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>
  </div>


  <div id="objectives-right">

    <objectivesCard  v-for="objective in rightcockpitmeasures" :key="objective._id" :columnnumber = "columnnumber" :activeCockpit="cockpit.isactive" ref="objectivedata1" :objectivedata="objective" :objectiveid="objective._id" :cockpit="cockpit" :loggedinuser="loggedinuser" :token="token" @onobjectiveloaded="objectiveloaded" @onshowobjectivesdescription="showobjectivedescriptionmodal" @onmemberlistcall="loadmemberlist"  @onmakeactive="makeactive" @onstatusupdate="addstatus" @onobjectivesettings="objectivesettings" @onopenmeasureinmodal="openmeasureinmodal" @onrequestaccess="requestaccess" @oncommentsupdated="updatecomments" :usemenu="true" :useswipe="true" :fulldesc="true" :scope="'index'" :feedback="'readwrite'" :perpage="10"></objectivesCard> 

    <objectivesCardworking v-if="rightcockpitmeasures.length < leftcockpitmeasures.length && cockpit.isactive && newobjectiveworking"></objectivesCardworking>

    <objectivesadd  :class="{hide:newobjectiveworking}" v-if="(rightcockpitmeasures.length < leftcockpitmeasures.length && cockpit.isactive) && ( cockpit.myrole && cockpit.myrole.toUpperCase() == 'OWNER')" :cockpit="cockpit" @oncockpitupdated="cockpitupdates++;cockpitupdated()" @onbeforecockpitupdated="onbeforecockpitupdated" :token="token" :addonly="false" :existingids="existingids"></objectivesadd>

  </div>
</template>


</div>  


<p class="clearfix"></p>
</div>
</main>
</div>
</template>
<style lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
@import'@/scss/abstracts/mixin.scss';




.iconwrapper:hover i, .iconwrapper:focus i{
  font-weight: 700;
}

  .fullscreen-modal .modal-content{
    /*height: 90vh;
    overflow: hidden;*/
  }
  .objective_titlewrapper{
      height: 64px;
     @include media-max-width($breakpoints-sm, $breakpoints-md){

      height:68px;  
    }
  }

  #objectives-left{
    width: 50%;
    float: left;
    padding-right: 5px;
  }

  #objectives-right{
    width: 50%;
    float: right;
    padding-left: 5px;
  }

  .preview{
    height: 60px;
    overflow: hidden;  
    
  }
  
  .overlay-fade{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }


  @media only screen and (max-width: 576px) {
    .cockpitsection .border-top{ 
      display: none; 
    }
  }


</style>
<script type="text/javascript"></script>
<script>
  // import objectivesCard from '@/components/objective/objectivesCard'

  import Newslettersapi from '@/services/api/newsletters'
  import Cockpitsapi from '@/services/api/cockpits'
  import {objectivesCardworking, objectivesCardfull, objectivesCard, cockpitsettingsmodal, cockpitStatistics, stakeholders, riskmanagement, masterplan, linkages, membersModal, addStatustoaggregator, addStatustoindicator, objectivesettingsmodal,  objectivesadd, objectiveModal, requestingaccess, cockpitdescription } from './components'
  
  import newsletterpages from '@/views/newsletter/newsletterpages'

  import reportsedit from '@/views/newsletter/reportsedit'
  import reportsview from '@/views/newsletter/reportsview'

  import portfolio from '@/views/portfolio/portfolio.vue'
  import portfoliodetail from '@/views/portfolio/portfoliodetail.vue'

  
  import {TopBar} from '@/components/topbar'
  import {bus} from '@/main'
  
  export default{
    name: 'cockpitView',
    props:{
      myobjectivesid:String
    },
    data(){
     return{
      cockpitindexupdated:0,
      status : 'loading',
      loggedinuser:{},
      token:"",
      showNewsletters:false,
      newsletters:[],
      cockpittitle:'',
      cockpit: {},
      cockpitid : "",
      cockpitedit : {},
      cockpitmembers:[],
      activetopbar : 0,        
      cockpitdescriptionmodalshown : false,      
      showobjectivedescription : false,
      activeobjective : {},
      modalobjective : {},
      modalobjectiveid : null,
      modalindicatorid : null,
      activeindicator : {},
      membersmodalshown : false,
      membersmodaltype: '',
      membersmodaleid:'',
      objectivemodalshown:false,
      newslettermodalshown: false,
      opennewsletterid:"",
      cockpitsettingsmodalshown : false,
      objectivesettingsmodalshown : false,
      requestaccessmodalshown: false,
      showmakeactive : { show:false, type:"", iid:"", isactive:"" },
      cockpitstatisticsmodalshown: false,
      addstatustoindicator : { objective:{}, type:"" , show:false },
      addstatustoaggregator : { objective:{}, type:"" , show:false },
      cockpitupdates : 0,
      objectiveupdates : 0,
      objectivesloaded : 0,
      showlinkedindicator: false,
      cardloaded : 0,
      swiperwrapper: null,
      swiper: null,
      viewernesletter: true,
      myactiverole: '',
      newobjectiveworking: false,
      tabs: 'Board',     
      // find submenu here 
      tabsobject: { tapes: [ 
            {title:'Objectives', route:'/',  name:'boardoverview', current:true },
            {title:'Master plan', route:'/masterplan', name:'boardmasterplan', current:false }, 
            {title:'Stakeholders', route:'/stakeholders', name:'boardstakeholders', current:false },
            {title:'Risks', route:'/riskmanagement', name:'boardriskmanagement', current:false }, 
            {title:'Reports', route:'/reports', name:'boardreports' , current:false },
            {title:'Statistics', route:'/statistics', name:'boardstatistics', current:false },
            //{title:'Items', route:'/items', name:'boarditems', current:false }
            ],            
            currenttap: 'board',
            tabsobject: "cockpit"

          },
      cockpitdescriptionmodalshownO:false,
      objectivemodalfailed: false,
      orgerror:"",
      
    }
  },

  computed:{

    routename(){

      return this.$route.name
    },

    stakeholdersenabled(){

      //console.log( this.cockpit.stakeholders )

      if( this.cockpit && this.cockpit.stakeholders && this.cockpit.stakeholders._id ){

        return true
      }else{

        return false
      }

    },

    /*tabsobjecttapes(){

      console.log( this.cockpit.stakeholders , "tapes")

      let tapes = 

      return tapes
    },*/

    /*
        tabsobject(){ 

      let tapes = [ 
            {title:'Board', route:'/',  name:'boardoverview', current:true },
            {title:'Newsletters', route:'/newsletters', name:'boardnewsletter' , current:false },
            {title:'Statistics', route:'/statistics', name:'boardstatistics', current:false }]

      if(  this.cockpit.stakeholders != null ){
        tapes.push( {title:'Stakeholders', route:'/stakeholders', name:'boardstakeholders', current:false } )
      }

      let topcontent = {tapes:tapes, 
            currenttap: 'board',
            tabsobject: "cockpit"
          }

      return topcontent

    },

    */

    existingids(){

      /*
          measuresnotinsections
    leftcockpitmeasures
    rightcockpitmeasures*/

      let arraycontainers = [ this.measuresnotinsections, this.leftcockpitmeasures, this.rightcockpitmeasures ]
      let existingids = []

      for( var o in arraycontainers ){
        if( arraycontainers[o] ){
          for( var i in arraycontainers[o] ){

            existingids.push( arraycontainers[o][i]._id )

          }
        }
      }

      return existingids
    },

    organization(){
          return this.$store.state.organizations.selectedorganizations
        },

    validorg(){

      if( this.organization.key && ( this.$route.params.orgname != this.organization.key )  ){
        return false
      }else{
        return true
      }


    },

    /**cockpitid(){
        return this.$route.params.cid
      },*/

    orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

    deviseDesktop(){

    
      return !this.devisesmall
    },

    devisesmall(){

      var toreturn = false

        if( this.$store.state.screenType == 'mobile' ){
          toreturn = true
          
        }

      return toreturn

    },

    currentactiveobjective(){


      var toreturn = null

      if( this.tabsobject['typeofpage'] == "myobjectives" ){

        if( this.cockpit.measuregroups ){
          for(var i in this.cockpit.measuregroups ){
            for(var o in this.cockpit.measuregroups[i] ){
              if( this.$route.query.oid == this.cockpit.measuregroups[i][o]._id ){
                toreturn = this.cockpit.measuregroups[i][o]._id
              }
            }
          }
        }

      }

      return toreturn
    },

    columnnumber(){
      return this.$store.state.screenSize <  769 ? 1:2;
    },

    groupeditem(){


      var toreturn = []

      if( this.tabsobject['typeofpage'] == "myobjectives" ){

        var sections = []

        if( this.cockpit.measuregroups ){            

          for(var i in this.cockpit.measuregroups ){     

            var label = ""

            if( i == 'a' ){
              label = 'Objectives where I am an owner'
            }
            if( i == 'b' ){
              label = 'Objectives where I am a contributor'
            }
            if( i == 'c' ){
              label = 'Objectives where I am a viewer'
            }              

            var objectivesdata = []
            var objectivesids = []
            for(var o in this.cockpit.measuregroups[i] ){              
              objectivesdata.push( this.cockpit.measuregroups[i][o] )
              objectivesids.push( this.cockpit.measuregroups[i][o]._id )
            }
            if(objectivesdata.length == 0){
              objectivesdata = null
            }

            sections.push( { "_id": 'item_'+i, "action":"", "cockpitid":"", "label":label, "objectives":objectivesids, "objectivesdata":objectivesdata, "sortorder":0  } )
          }


        }
        toreturn = sections

      }else{
        toreturn = this.cockpit.sections
      }

      return toreturn
    },


    measuresnotinsections(){

      var toreturn = []

      if( this.tabsobject['typeofpage'] == "myobjectives" ){
        toreturn = []
      }else{
        toreturn = this.cockpit.measuresnotinsections
      }
    },



    leftcockpitmeasures(){

      var toreturn = []
      var items = this.cockpit.measuresnotinsections

      if( this.tabsobject['typeofpage'] == "myobjectives" ){
        items = []
      }

      for (var i in  items ) {

        if( i % 2 == 0 ){
          toreturn.push( items[i] )
        }
      }

      return toreturn
    },

    rightcockpitmeasures(){

      var toreturn = []
      var items = this.cockpit.measuresnotinsections

      if( this.tabsobject['typeofpage'] == "myobjectives" ){
        items = []
      }
      
      for (var i in items ) {

        if( i % 2 != 0 ){
          toreturn.push( items[i] )
        }
      }

      return toreturn

    },

  },

  watch:{

   /* cockpit: function(e){

      if(  e.stakeholders && e.stakeholders._id != null ){

        let tab = {title:'Stakeholders', route:'/stakeholders', name:'boardstakeholders', current:false }
        //this.tabsobject.tapes.push(  tab )

        //this.opentabs()
      }

      
    },*/

    '$route.params.cid': function(){
      let self = this
      self.tabs = 'board'
      this.cockpit = {}

       

      if( this.$route.name == 'objectiveovervieworgs' ){
        
        this.tabsobject['typeofpage'] = "myobjectives"
        this.tabsobject.currenttap =  'board'
        document.title ='objectives | Flowe'

      }else if( this.$route.name == 'portfolioovervieworgs' || this.$route.name == 'portfoliooverviewdetail' ){

        this.tabsobject['typeofpage'] = "portfolio"
        this.tabsobject.currenttap =  'portfolio'
        document.title ='Portfolios | Flowe'

      }else{
        this.tabsobject['typeofpage'] = "cockpit"
      }

      this.loadCockpits( function(){
         if( self.tabsobject['typeofpage'] == "myobjectives" ){
          document.title ='Objectives | Flowe'

        }else{

          document.title = self.cockpit.name+' | Flowe'

        }

        

        let cockpitid = self.$route.params.cid
        let objectiveid = self.$route.params.objectiveid
         
        setTimeout( function(){
          bus.$emit('initobjectivesadd')
        }, 100)
        if(self.tabsobject['typeofpage'] != "myobjectives"){
          self.updatetab()
         }
        })
    },

    '$route.query.oid': function(e){
      
    },

    '$route.query.iid': function(e){
      
    },

    '$route.query.page': function(e){


      let self = this
      if(e == undefined){
        if( self.tabsobject['typeofpage'] == "myobjectives" ){
                document.title ='Objectives | Flowe'

              }else{

                document.title = self.cockpit.name+' | Flowe'

              }
        } 
    },

    '$route.query.modal': function(e){

     // alert('ccccccc')

      if (e != 'yes'){
       // this.objectivemodalshown = false 
      } if (e == 'yes'){
       // this.objectivemodalshown = true
      }
    }
    
  },
  beforeRouteLeave ( to, from, next ){

      if( this.$refs.reportseditconf && this.$refs.reportseditconf.prepunload && from.name == 'boardreportsedit' ){
        this.$refs.reportseditconf.prepunload()
      }

      next()
    },

  /*created (){    
        window.addEventListener('beforeunload', () => {

            alert('xxxxxxxx')

            return null
         })


        window.onbeforeunload = function (event) {
          alert('bbbccvcxvxcvxc')
        }
    },*/

  

  mounted() {

    
    $('#preloader').removeClass('hide')

     if( this.$route.name == "boardnewsletter" && this.$route.query.page == "newsletter" && this.$route.query.nid != "" ){

        var tourl = "/" + this.orgkey + "/boards/" + this.$route.params.cid + "/reportsview?nid=" + this.$route.query.nid + "&page=newsletter&openexisting=true"
       // this.$router.push( tourl )

        window.location = tourl

        return false

     }

     //;

     if(  this.$route.name == 'boardreportsedit' ){
      this.tabsobject.currenttap = 'boardreports'
     }

     if(  this.$route.name == 'boardreportsview' ){
      this.tabsobject.currenttap = 'boardreports'
     }

     if(  this.$route.name == 'portfolioovervieworgs' || this.$route.name == 'portfoliooverviewdetail' ){
      this.tabsobject.currenttap = 'portfolio'
     }
      

    if( this.$route.name == 'objectiveovervieworgs' ){
        this.tabsobject['typeofpage'] = "myobjectives"
          //console.log('objectives',this.$route, this.tabsobject['typeofpage'] )
    }else{
        this.tabsobject['typeofpage'] = "cockpit"
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })


    this.cardloaded = 0

    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
    })

    this.token = localStorage.token

    let self = this

     bus.$on('goback', function(){
        self.addstatustoindicator.show = false
        self.addstatustoaggregator.show = false
     })

          //console.log( self.loggedinuser._id , "ID")

          this.loadCockpits( function(){
            if( self.tabsobject['typeofpage'] == "myobjectives" ){
                document.title ='Objectives | Flowe'

              }else{

                document.title = self.cockpit.name+' | Flowe'

              }

            let cockpitid = self.$route.query.cid
            let objectiveid = self.$route.query.oid
            let statusid = self.$route.query.iid



            setTimeout( function(){
              bus.$emit('initobjectivesadd')
            }, 100)


            if(self.tabsobject['typeofpage'] != "myobjectives"){
                    self.updatetab()
                }

                if( objectiveid ){
                  if(self.$route.query.modal == 'yes'){
                    //alert('')
                    //console.log( self.o)
                    self.openmeasureinmodal(objectiveid, statusid)
                  }
                }


                if( self.$route.query.page == "description" ){
                  self.oncockpitdescription()
                }else if ( self.$route.query.page == "members" ){

                  

                  if( objectiveid ){
                    self.loadmemberlist('objective', objectiveid, null)
                  }else{
                    self.loadmemberlist('cockpit', self.cockpit._id, null)                    
                  }         
                  
                

              }else if ( self.$route.query.page == "settings" ){
                self.cockpitsettings(self.cockpit._id)
              }
              
            })
          

          $('.modal').modal('hide');
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open')

          //bus.$off('onopennewsletterfromfeeds', this.opennewslettermodal )
          //bus.$off('opennewsletters',this.opennewsletters)
          //bus.$on('onopennewsletterfromfeeds', this.opennewslettermodal )
          //bus.$on('opennewsletters',this.opennewsletters)
          bus.$on('doneloading', self.openroute)
        }, 

  methods:{

    objectiveupdated( update ){

      this.cockpitupdates++


    },

    boardupdated( update ){

     //// console.log( update, "zzzzzzzzzzzzzzzzz")
      
      this.cockpitupdates++
     // this.cockpitmembers = []

      if( update && update.removed ){

        let newcockpitmembers = []

        for(var i in this.cockpitmembers){

          if(  update.removed != this.cockpitmembers[i]._id ){
            newcockpitmembers.push( this.cockpitmembers[i] )
          }
          
        } 

        this.cockpitmembers = newcockpitmembers

      }

      if( update && update.added && update.selected ){

        
        this.cockpitmembers.push( update.selected )

      }

      this.cockpitindexupdated++
      
     // this.loadmemberlist('objective',  this.modalobjectiveid , null)
    },

    membersModalHidden(){


    /*let query = Object.assign({}, this.$route.query);

    console.log( query, "query")
    delete query.oid;
    delete query.page;
    console.log( query, "query")

    this.$router.replace({ query });

  */
    //this.$func.removeQuery(['page','modal','oid'],this.$route, this.$router)
     this.membersmodalshown=false
    },

  addstatushidden(){


    this.addstatustoindicator.show = false
    //console.log('test')
    if( this.$route.name == 'objectiveovervieworgs' ){
      this.tabsobject['typeofpage'] = "myobjectives"
    }else if( this.$route.name == 'portfolioovervieworgs' || this.$route.name == 'portfoliooverviewdetail' ){
      this.tabsobject['typeofpage'] = "portfolio"
    }else{
      this.tabsobject['typeofpage'] = "cockpit"
    }
    this.tabsobject.currenttap = 'board'
  },

  modalobjectiveloaded( objective ){

    //console.log( objective )

    this.modalobjective = objective
            //console.log( objective, "CCC")
  },

  linktodescription(){

    this.$func.addQuery({page:'description'},this.$route, this.$router)
  },
          linktosettings(){
           this.$func.addQuery({page:'settings'},this.$route, this.$router)
         },
         linktomembers(){
           this.$func.addQuery({page:'members'},this.$route, this.$router)
         },
         updatecomments(){
          
         },

         leftsection( measureinsections ){

          var toreturn = []

          for (var i in measureinsections) {

            if( i % 2 == 0 ){
              toreturn.push( measureinsections[i] )
            }
          }

          return toreturn
        },

        rightsection( measureinsections ){

          var toreturn = []

          for (var i in measureinsections) {

            if( i % 2 != 0 ){
              toreturn.push( measureinsections[i] )
            }
          }

          return toreturn

        },

        onbeforecreate(){
          this.status = 'posting'
        },

        togglepreview( ev){

          // console.log( $(ev.currentTarget).find('.preview') )
        },
        // route.path
        updatetab(){

          for(var i in this.tabsobject.tapes){
             if(this.$route.name == this.tabsobject.tapes[i].name){
              this.tabsobject.tapes[i].current = true
              this.tabsobject.currenttap = this.tabsobject.tapes[i].name.toLowerCase()
             } else {
              this.tabsobject.tapes[i].current = false
             }
            }

        },
        
        opentabs(tab){
          let self = this



          if( !tab ){
            for(var i in self.tabsobject.tapes){          
             if(self.$route.name == self.tabsobject.tapes[i].name){
              self.tabsobject.tapes[i].current = true
              self.tabsobject.currenttap = self.tabsobject.tapes[i].title.toLowerCase()
             } else {
              self.tabsobject.tapes[i].current = false
             }
            }
          }

          //console.log( tab.name , "opentabs")

          
           this.$router.push({name:tab.name, params: {cid: this.$route.params.cid}}).then(()=>{
            self.updatetab()
           }).catch(err => { 
            console.log(err)
            })

          


        },

        cockpitobjectivesloaded(){

          //if( this.$route.params.newsletterid ){
          //console.log( this.cockpit, "cockpit" )

         // this.opennewsletterid = this.$route.params.newsletterid
          //this.newslettermodalshown = true
          
         // }

      },

      objectiveloaded( objectiveid ){

        this.objectivesloaded += 1

        if( this.objectivesloaded == this.cockpit.measures.length ){
          this.cockpitobjectivesloaded()
        }

      },

      requestsubmitted(){

        this.activeobjective.userstatus = 'pendingjoin' 

        this.loadCockpits()

      },

      onobjectivemodalshown(){

        let self = this 

        Cockpitsapi.Objectiveview( this.modalobjectiveid, this.token, this.orgkey )
        .then( function(response){

          if( response.data.ok && response.data.measure){
            
            self.modalobjective = response.data.measure

            self.$nextTick(function() {
              bus.$emit('modalobjectiveshown', self.modalobjectiveid , self.modalindicatorid)              
            })

            self.objectivemodalfailed = false            
              
          }else{
/////
            self.objectivemodalfailed = true
          }

          })
        .catch(error => console.log(error))
        .finally( () => {

        })

      },

      onobjectivemodalhidden(){

        bus.$emit('onreloadobjective', this.modalobjective._id )


        this.$func.removeQuery(['page','modal'],this.$route, this.$router)

        this.modalobjective = {}
        
        //this.$router.go(-1)
        $('[data-toggle="tooltip"]').tooltip('hide')
        
      },

      openmeasureinmodal( measureid , indicatorid , isreload){

      


        //var obj = 


       // if( this.currentactiveobjective ){      

          // log the event to tab clicked
          

          if( indicatorid == '' ){
            indicatorid = "null"
          }

          //console.log( this.objectivemodalshown , "pppppppppppppppp")


          if( this.objectivemodalshown ){

            /*this.objectivemodalshown = false

            setTimeout( function(){

              alert('')

              self.modalobjectiveid = measureid
              self.modalindicatorid = indicatorid
              self.objectivemodalshown = true

              //alert('')
              ////self.modalobjectiveid = measureid
              ////self.modalindicatorid = indicatorid

              ///bus.$emit('onreloadobjectivedata', measureid, indicatorid)
              //self.objectivemodalshown = true
            }, 5000)*/
          }else{

            this.modalobjectiveid = measureid
            this.modalindicatorid = indicatorid
            
            let toshow = false

            if( !this.modalobjective._id ){
              toshow = true
            }

            this.objectivemodalshown = toshow

            //console.log( this.modalobjective._id , "xcacasfasfasfaasfaf")

//            alert('nnnnnnnn')

          }
          // console.log('objectivemodalshown',this.objectivemodalshown)

       // }else{
       //   history.pushState("", document.title, "/objectives" );
       // }

      },

      shownewsletterlist(){
        this.viewernesletter = false
        //this.newslettermodalshown = true
      },


      cockpitstatistics(){

      },

      onbeforecockpitupdated(){

        this.newobjectiveworking = true

      //this.cockpit.measures.push( { "_id":"null" } )

    },
    oncockpitdescription(){
      let self = this
      

      
      bus.$emit('onopendescription',self.cockpit._id, self.loggedinuser, self.token, self.cockpit.myrole,'cockpit', false, this.cockpit.isactive )
      this.linktodescription()
      this.$nextTick(function(){
        self.cockpitdescriptionmodalshown = true
      })
    },

    onobjectivesettingsmodalhidden(){

      this.objectivesettingsmodalshown = false
      this.cockpitupdated()
      this.cockpitupdates = 0


    },

    cockpitforceupdates(){

      let self = this

      //this.cockpitupdates = withchanges

      setTimeout( function(){
        self.cockpitupdated()
      }, 100)

    },

    oncockpitsettingsmodalhidden( withchanges ){

      let self = this
      
      this.cockpitsettingsmodalshown = false
      
      this.cockpitupdates = withchanges

      setTimeout( function(){
        self.cockpitupdated()
      }, 100)

    },


    cockpitupdated(){

      this.cockpitupdates += 1


      let self = this

      if( this.cockpitupdates > 0 ){

        this.loadCockpits( function(){
          
          self.newobjectiveworking = false
          self.$store.state.forceRefresh

          setTimeout( function(){
            bus.$emit('initobjectivesadd')            
          }, 100)
          
          
        })
      }      
    },

    addnewindicatorsuccess( objectiveid, indicator ){


      
      this.addstatustoindicator.show=false
      this.addstatustoaggregator.show=false 

      //if( this.devisesmall ){
      //  this.addstatushidden()
      //}

      bus.$emit('onobjectiveupdate', objectiveid, indicator )

    },

        addstatus( type, objective ){


          if( type == 'measure' || type == 'risk' || type == 'masterplan' || type == 'stakeholder' ){

           // 
              this.addstatustoindicator.objective = objective
              this.addstatustoindicator.show = true

              if( this.devisesmall ){
                // this.tabsobject['typeofpage'] = 'addstatuspage'
                //  this.tabsobject.currenttap = ''

                //setTimeout( function(){
                //  bus.$emit('addstatusfrompage')
                //}, 300)                
              }
            //}else{
             // alert("xxxx")
           // }
            
          }

          //alert('aggregator')

          if( type == 'aggregator'  ){


            this.addstatustoaggregator.objective = objective
            this.addstatustoaggregator.show = true

            /*if( this.devisesmall ){
                this.tabsobject['typeofpage'] = 'addstatuspage'
                this.tabsobject.currenttap = ''

                setTimeout( function(){
                  //alert('cccc')
                  bus.$emit('addstatusfromaggregatorpage')
                }, 300)                
              }*/

          }
        },

        domakeactive( isactive, type, iid ){

          let self = this
          if( type == 'cockpit' ){

            Cockpitsapi.Makeactive({ 'type':'cockpit', 'setto': isactive ,'cid': iid })
            .then( function(response){

              if( response.data.ok ){    
                self.cockpit.isactive = self.cockpit.isactive ? false : true 

                self.$store.dispatch('updateCockpitActivity',{ cid:iid, isactive: self.cockpit.isactive})
                //console.log( isactive, "xxxx" )

              }

            })
            .catch(error => console.log(error))
            .finally( () => {
            //do everything
            self.showmakeactive.show = false
          })


          }

          if( type == 'objective' ){

            Cockpitsapi.Makeactive({ 'type':'measure', 'setto': isactive ,'cid': iid })
            .then( function(response){

              if( response.data.ok ){ 
                
                bus.$emit('makeactive', iid)
                if(isactive == "yes" ){
                   var str = ' The objective was successfully activated and is now visible to all objective members.'
                   var str1 = 'Objective has been reactivated'
                  bus.$emit('craeteaToast', {content:str, title:str1, variant:'success', append:true, sup:''})
                }
               
              }

            })
            .catch(error => console.log(error))
            .finally( () => {
            //do everything
            self.showmakeactive.show = false
          })

          }


        },

        makeactive( isinactive, type, iid ){ 
          // console.log('makeactive')

          if( isinactive == 'no' ){
            this.showmakeactive.show = true
            this.showmakeactive.type = type
            this.showmakeactive.iid = iid
            // console.log(this.showmakeactive.show,this.showmakeactive.type,this.showmakeactive.iid)
          }else{
            this.domakeactive( 'yes', type, iid )
          }


        },

        showobjectivedescriptionmodal( objective ){
          let self = this
          this.activeobjective = objective
          
          bus.$emit('onopendescription',this.activeobjective._id, self.loggedinuser, self.token, this.activeobjective.myrole, 'objective', false, this.activeobjective.isactive)
          this.$nextTick(function(){
            self.cockpitdescriptionmodalshown = true
          })
        },

        emitfound(){

        //  console.log("emit found")
      },

      getWindowWidth(){
      

      },

      reloadData(){



          //this.cockpit = {}
          //alert("bbb")   
          //this.loadCockpits()

         // console.log( indicator )

       },

       loadNewsfeed(){
        let self = this

        Newslettersapi.Getfeed( self.loggedinuser._id )
        .then( function(response){

          if( response.data.ok ){
            //console.log(response.data.newsletters)
            self.newsletters = response.data.newsletters;


          }

        })
        .catch(error => console.log(error))
        .finally( () => {

          this.showNewsletters=true;
          //console.log( this.showNewsletters)

        })

      },

      isEmpty(obj) {
        
        for(var key in obj) {

          if(obj.hasOwnProperty(key)){ return false }

        }

      return true
      
    },

    loadCockpits( callback ){

          let self = this
          var cid = ""

          var thisPromise = null

          if( this.tabsobject['typeofpage'] == "myobjectives" ){
            cid = ""
          }else{
            cid = self.$route.params.cid
          }

          this.$store.dispatch('fechtCockpit',{cid:cid, orgkey: this.orgkey, token: this.token}).then( function(response){
            if( response.data.ok){             

              self.cockpit = response.data.cockpits
              self.orgerror = response.data.orgerror

              if( self.cockpit.isactive == false && self.cockpit.myrole == 'viewer' ){
                bus.$emit('onupdatedisplayrouterview', 400)
              }

              if( callback ){
                callback()
              }
              
            }else{

              if( response.data.error == "cockpitdoesnotexists" ){
                bus.$emit('onupdatedisplayrouterview', 400)
                //self.$router.push("/400")
              }
              if( response.data.error == "cockpitnopermission" ){
                //console.log(self, "403")

                bus.$emit('onupdatedisplayrouterview', 403)
              }


            }

          })
          .catch(error => console.log(error))
          .finally( () => {

            self.status = 'idle'
            
            $('#preloader').addClass('hide')
  
          })


        },

        cockpitsettings( cockpitid ){
          if(this.cockpit.isactive){
            this.cockpitsettingsmodalshown = true
            this.cockpitid = cockpitid
            this.linktosettings()        
          } else {
            this.showmakeactive.show = true
            this.showmakeactive.type = 'cockpit'
            this.showmakeactive.iid = cockpitid
            this.showmakeactive.isactive = false
          }


        },

        showsettings(iid, type){
          if(type == 'cockpit'){
            this.cockpitsettingsmodalshown = true
            this.cockpitid = cockpitid 
          } else if (type == 'objective'){
            this.objectivesettingsmodalshown = true
          }


        },
        objectivesettingsonrefrech(oid){
          this.activeobjective._id = oid;
          this.objectivesettingsmodalshown = true
        },

        objectivesettings( measuretype, objective ){
          this.activeobjective = objective
          this.objectivesettingsmodalshown = true
        },


        loadmemberlist( type, iid, objective ){

         /* objectivesettings( measuretype, objective ){
          this.activeobjective = objective
          this.objectivesettingsmodalshown = true
        }*/

        let self = this
        if(objective){
          this.myactiverole = objective.myrole
        } else {
          this.myactiverole = this.cockpit.myrole
        }
        this.membersmodaltype = type
        this.membersmodaleid = iid

        if( type == 'cockpit' ){

            //history.pushState("", document.title, "/dashboard/cockpit/id_" + this.cockpit._id + "/members" );


            Cockpitsapi.Cockpitmembers( iid, this.token, this.orgkey )
            .then( function(response){   


              /*for( var i in response.data.users ){
                console.log( response.data.users[i].user.orgrole , "zzzz")
              }*/

              if( response.data.ok){
               // self.cockpitmembers = response.data.users

               let members = []


               if( response.data.users ){
                  members = members.concat(response.data.users);                            
               }

               if( response.data.teams ){
                  members = members.concat(response.data.teams);
                            
               }

               self.cockpitmembers = members


                self.membersmodalshown = true     

                



              }else{

               // console.log( response.data , "zzxx")
              }

            })
            .catch(error => console.log(error))
            .finally( () => {
            })

            this.linktomembers()
          }

          if( type == 'objective' ){

            //history.pushState("", document.title, "/dashboard/cockpit/id_" + this.cockpit._id + "/objectivemembers/" + iid );

            Cockpitsapi.Objectivemembers( iid, this.token, this.orgkey )
            .then( function(response){
              
              if( response.data.ok ){

                

                let members = []

                if( response.data.users ){
                  members = members.concat(response.data.users);
                }

                if( response.data.teams ){
                  members = members.concat(response.data.teams);
                }

                self.cockpitmembers = members

                

                /*for(var i in response.data.users){
                  console.log(  response.data.users[i].user._id, response.data.users[i].role, "zzzzzzzzzz")
                }*/               
                
                


                if( !response.data.users ){
                  self.membersmodalshown = false
                }else{
                  self.membersmodalshown = true
                }
                
              }

            })
            .catch(error => console.log(error))
            .finally( () => {
              if( objective ){
                this.activeobjective = objective
              }
              
            })

          }




        },
        destroy() {
          this.$destroy();
        },
        setcockpitactivity : function(){
          if(this.cockpit.isactive){
           Cockpitsapi.Makeactive({'setto':'no','cid':this.cockpit._id}).then( function(response){

            console.log("no")
           })
         }else if (!this.cockpit.isactive){
           Cockpitsapi.Makeactive({'setto':'yes','cid':this.cockpit._id}).then( function(response){

             console.log("yes")
           })
         }

       },
       destroy() {
        this.$destroy();
      },
      setcockpitactivity : function(){
        if(this.cockpit.isactive){
         Cockpitsapi.Makeactive({'setto':'no','cid':this.cockpit._id}).then( function(response){

            console.log("no2")
           })
       }else if (!this.cockpit.isactive){
         Cockpitsapi.Makeactive({'setto':'yes','cid':this.cockpit._id}).then( function(response){

            console.log("yes2")
           })
       }

     },

     requestaccess(objective){
      this.activeobjective = objective;
      this.requestaccessmodalshown = true;
    },
    onmodaldescriptionhidden(){
//////////xxxxxxxxxxxxx
     this.cockpitdescriptionmodalshown = false
     if(this.cockpitupdates > 0){
      this.cockpitupdated()
      this.cockpitupdates = 0
    }
  }

},

components:{
  objectivesCardworking,
  objectivesCardfull,
  objectivesCard,
  TopBar,
  cockpitsettingsmodal,
  membersModal,
  addStatustoaggregator,
  addStatustoindicator,
  objectivesettingsmodal,
  cockpitStatistics,
  stakeholders,
  riskmanagement,
  masterplan,
  linkages,
  objectivesadd,
  requestingaccess,
  newsletterpages,
  reportsedit,
  reportsview,
  cockpitdescription,
  portfolio,
  portfoliodetail
},

}
</script>